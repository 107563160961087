body {
  background-color: $dark;
  color: $light;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  color: $light;
  text-decoration: underline;
}

footer{
  height: 100vh;

  background-color: black;

  margin: 0 0 0;
  width: 100%;
  position: relative;

  display: flex;
  justify-content: space-between;

  color: $light;

  @media screen and (max-width: $break-small){
    flex-direction: column;
  }

  .about-marquee{
    width: 100%;
    height: 100vh;
    position: absolute;
    overflow-y: hidden;

    textPath{
      fill: rgb(37, 37, 37);
    }
  }

  .footer-about{
    align-self: flex-end;
    max-width: 300px;
    width: $width-small;
    margin: 2vw;

    @media screen and (max-width: $break-small){
      margin: 200px 2vw 50px;
    }
  }

  .footer-copyright{
    align-self: flex-end;
    max-width: 300px;
    margin: 2vw;
  }
}


::-moz-selection { /* Code for Firefox */
  color: $dark;
  background: $light;
}

::selection {
  color: $dark;
  background: $light;
}


.reveal, p {
  visibility: hidden;
}