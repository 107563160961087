header{
  width: calc(#{$width-large} - 4vw);
  margin: 150px auto 75px;

  @media screen and (max-width: $break-small){
    max-width: $width-small;
    margin: 150px auto 50px;
  }

  h2{
    color: transparent;
    -webkit-text-stroke: 1px $light; 
    -webkit-text-fill-color: transparent;
  }

  h3{
    margin: 50px 0;

    @media screen and (max-width: $break-small){
      margin: 20px 0 0;
    }
  }

  .article-meta{
    display: flex;
    z-index: 100;
    position: relative;
    justify-content: center;

    @media screen and (max-width: $break-small){
      justify-content: left;
    }

    h3{
      margin-right: calc(20px + 3vw);

      &:last-child{
        margin-right: 0;
      }
    }
  }
}

.intro{
  width: $width-large;
  max-width: 1000px;

  @media screen and (max-width: $break-small){
    width: $width-small;
  }
}

article{
  margin-bottom: 50px;

  p{
    padding: 20px 0;
    max-width: $width-p;
    width: $width-large;
    margin: 0 auto;

    @media screen and (max-width: $break-small){
      width: $width-small;
      padding: 10px 0;
    }

    strong{
      padding: 20px 0;
      max-width: 980px;
      margin: 0 auto;
    }
  }

  .blockquote{
    max-width: 680px;
    padding: 20px 0;
    margin: 0 auto;

    @media screen and (max-width: $break-medium){
      padding: 30px 30px;
    }
  }

  .left{
    right: 12vw;
    max-width: calc(680px - 2vw);

    @media screen and (max-width: $break-medium){
      right: 0;
      padding-left: 5vw;
      width: 80vw;
    }
  }

  .right{
    left: 12vw;
    max-width: calc(680px - 2vw);

    @media screen and (max-width: $break-medium){
      left: 0;
      padding-left: 15vw;
      width: 75vw;
    }
  }

}



img, video{
  width: 100%;
  position: relative;
  z-index: -2;
}

figure, .image-inline-full{ 
  margin: 100px auto;
  text-align: center;
}

.image-header{
  width: $width-large; 
  margin: 50px auto;
}

.image-inline-large{
  width: $width-large;
  max-width: 1200px;
}

.image-inline-small{
  width: 60vw;
  max-width: 500px;
  min-width: 200px;
}

.image-inline-small-underlap{
  width: 60vw;
  max-width: 500px;
  min-width: 200px;
  margin: 100px auto -300px;
}

.image-inline-full{
  width: $width-large;

  display: flex;

  .half{
    width: 50%;
    padding: 2px;
  }

  .wide{
    width: 70%;
  }

  @media screen and (max-width: $break-medium){
    display: block;

    .half{
      width: 100%;
      padding: 0;

      &:nth-child(odd){
        margin: 100px auto 0;
      }

      &:nth-child(even){
        margin: 0 auto 100px;
      }
    }
  }
}

.divider{
  border-left: 1px solid $light;
  height: 200px;
  width: 1px;
  margin: 50px auto;

  position: relative;
  z-index: 1;

  display: none;
}



input.regret{
  border: none;

  text-align: center;
  font-weight: 100;
  opacity: 1;
  
  padding: 0 2px;
  width: 50px;
  min-width: 50px;
  max-width: 400px;
  transition: width 100ms ease ;

  background: none; 

  &:focus{
    outline: none;
  }
}

::-webkit-input-placeholder {   color: rgba(255,255,255,0.3);  }
:-moz-placeholder { /* Firefox 18- */   color: rgba(255,255,255,0.3);  }
::-moz-placeholder {  /* Firefox 19+ */   color: rgba(255,255,255,0.3);  }
:-ms-input-placeholder {  color: rgba(255,255,255,0.3);  }