$type-large: 9vw;
$type-large-mobile: 15.5vw;
$type-small: 12px;
$type-small-mobile: 10px;

@font-face {
  font-family: 'syne';
  font-style:  normal;
  font-weight: 900;
  src:  url('fonts/Syne-Extra.woff') format("woff"),
        url('fonts/Syne-Extra.otf') format("otf"),
        url('fonts/Syne-Extra.woff2') format("woff2");
}


@font-face {
  font-family: 'willton';
  font-style:  normal;
  font-weight: 200;
  src: url('fonts/willton.woff') format("woff"),
        url('fonts/willton.ttf') format("ttf"), 
        url('fonts/willton.woff2') format("woff2");
}


// General

body{
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 28px;
  font-weight: 400;

  @media screen and (max-width: $break-small){
    font-size: 24px;
  }
}

nav{
  text-transform: uppercase;
}

nav, footer{
  font-size: $type-small;
  line-height: 1.5;
  text-decoration: none;

  @media screen and (max-width: $break-small){
    font-size: $type-small-mobile;
  }
}





// Main


h1, h2{
  font-size: $type-large;
  font-family: 'willton', serif;
  font-weight: 200;
  // letter-spacing: 1px;
  z-index: .6;

  @media screen and (max-width: $break-medium){
    font-size: $type-large-mobile;
  }
}

h1{
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 10;
}

h2{
  line-height: .95;
  padding: 0;
  margin: 0;
  position: relative;
  

  @media screen and (max-width: $break-medium){
    margin: 0 0;
  }
}

h3{
  font-size: $type-small;
  font-weight: 400;
  line-height: 1.2;

  text-transform: uppercase;

  @media screen and (max-width: $break-medium){
    font-size: $type-small-mobile;
  }
}

h5{
  font-size: $type-small;
  font-weight: 900;

  text-transform: uppercase;

  padding: 0;
  margin: 0;
  position: relative;
  z-index: 1;
}

p{
  font-weight: 300;
  line-height: 1.35;
  z-index: 20;
  position: relative;
}

a{
  text-decoration: underline;

  &:hover{
    text-decoration: none;
  }
}




// Article

.article{
  a{
    text-decoration: underline;

    &:hover{
      text-decoration: none;
    }
  }

  .em{
    font-weight: 800;
  }
}

header{
  line-height: 1;
  text-align: center;

  @media screen and (max-width: $break-small){
    text-align: left;
  }
}

.intro{
  font-size: 32px;
}

.rating{
  font-size: 18vw;
  text-align: center;
  display: flex;
  justify-content: center;
}




// Home
 
.index-info, .index-author, .index-issue{
  font-size: $type-small;
  text-transform: uppercase;
  line-height: 1.5;

  a{
    text-decoration: none;
  }
}

.home{
  h1,h2{
    font-size: 9vw;

    @media screen and (max-width: $break-small){
      font-size: 18vw;
    }
  }
}




// Quotes


#large-block{
  font-family: 'syne', sans-serif;
  font-size: 8vw; 
  line-height: .8;
  text-transform: uppercase;
  letter-spacing: -1px;

  @media screen and (max-width: $break-small){
    font-size: 9vw;
    word-break: break-word;
  }
}

#large-scroll-container, #headline{
  font-family: 'syne', sans-serif;
  font-size: 30px; 
  font-weight: 900;
  letter-spacing: 2px;
  line-height: .9; 

  @media screen and (max-width: $break-small){
    font-size: 35px;
  }
}




#large-horizontal-outline{
  textPath{
    font-family: 'syne', sans-serif;
    font-size: 100px;
    font-weight: 900;
  }
}

#footer-marquee{
  font-family: 'syne', sans-serif;
  font-size: 10px; 
  font-weight: 900;
  letter-spacing: 2px;
  line-height: .9;

  @media screen and (max-width: $break-small){
    font-size: 30px; 
  }
}