// Screen Sizes
$break-small: 600px;
$break-medium: 900px;
$break-large: 1200px;

// Screen Sizes
$width-large: 96vw;
$width-small: 90vw;

$width-p: 720px;

// Color
$dark: rgb(10, 10, 10);
$light: #bebab4;

// Animation
$transition: all 300ms ease;


