nav{
  position: fixed;
  width: $width-large;
  top: 1vw;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 100;

  display: flex;
  justify-content: space-between;

  .menu-main, .menu-second{
    display: flex;

    @media screen and (max-width: $break-small){
      display: block;
    }

    div{
      @media screen and (max-width: $break-small){
        margin: 5px 0;
      }
    }

    .menu-logo, .menu-share{
      opacity: .5;
    }
  }

  .menu-main{
    div{
      margin-right: 3vw;

      @media screen and (max-width: $break-small){
        margin-right: 10vw;
      }
    }
  }

  .menu-second{
    div{
      margin-left: 3vw;
    }
  }

  @media screen and (max-width: $break-small){
    width: $width-small;
    justify-content: left;
  }
}
