path {
  fill: transparent;
}

#headline{
  position: absolute;
  top: 0;
  margin: 0;
  z-index: 5;
  opacity: 1;
  // visibility: hidden;
}

#large-scroll-container{
  position: relative;
  margin: -20vw 0;
  z-index: -1;
}

#large-horizontal-outline{
  position: relative;
  margin: 100px 0;
  z-index: -1;
} 

#large-block{
  width: $width-large;
  margin: 100px auto;
  padding: 0;
  
  color: transparent;
  -webkit-text-stroke: 1px $light;

  transition: color 800ms ease-in-out;

  &:hover{
    color: $light;
    -webkit-text-stroke: none; 
  }  
}

#footer-marquee{
  position: absolute;
  top: 0;
  margin: 0;
  z-index: 5;
  opacity: 1;

  @media screen and (max-width: $break-small){
    top: 50vh;
  }
}

