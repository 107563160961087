ul{
  list-style: none;
  margin: 0;
  padding: 0;

  li{
	margin: 50px 0;

	@media screen and (max-width: $break-medium){
		margin: 60px 0;
	}
  }
} 


.home{
	#headline{
		z-index: 2;
		pointer-events: none;
	}

	.menu-index{
		display: none;
	}
}

.index-info{
	display: flex;
	width: 100%;
	margin: -30px 0 0 0;
	z-index: -1;

  	div{
		display: inline;
		width: 15%;

		@media screen and (max-width: $break-small){
			width: 33.3333333%;
			display: block;
			margin: 0 10px 0 0;
		}
	}

	.index-arrow svg{
		width: 11px;
		margin: 3px 0 0;

		transition: 200ms all ease;

		@media screen and (max-width: $break-small){
			width: 9px;
		}
	}
}

.index-title{
  padding: 10px 0 10px 25px;
  margin: 0;
  position: relative;
  z-index: 1;

  @media screen and (max-width: $break-medium){
    padding: 0;
  }
}

 



.menu {
	user-select: none;
	padding: 0 0 100px;
	--marquee-width: 100vw;
	--offset: 20vw;
	--move-initial: calc(-25% + var(--offset));
	--move-final: calc(-50% + var(--offset));
	--item-font-size: 6vw;
	counter-reset: menu;

	@media screen and (max-width: $break-small){
		padding: 10vh 0 100px;
	}
}

.menu-footer {
	padding: 0;
}

.menu__item {
	cursor: default;
	position: relative;
	padding: 10px calc((100vw - #{$width-large}) / 2);
	overflow: hidden;

	@media screen and (max-width: $break-small){
		padding: 0 calc((100vw - #{$width-small}) / 2);
	}

	a{
		text-decoration: none;
	}

	&:hover .index-arrow svg{
		margin-left: 20px;
	}
}

.menu__item-link {
	display: inline-block;
	cursor: pointer;
	position: relative;
	transition: opacity 0.4s;
}

.menu__item-link::before {
	all: initial;
	position: absolute;
	bottom: 60%;
	left: 0;
	pointer-events: none;
}

.menu__item:hover .menu__item-link{
	transition-duration: 100ms;
	opacity: 0;
}

 
/* Make sure 3 items are visible in the viewport by setting suitable font size. */



.marquee {
	position: absolute;
	top: 0;
	left: 0;
	width: var(--marquee-width);
	// overflow: hidden;
	pointer-events: none;
}

.marquee__outer {
	width: fit-content;
	display: flex;
	position: relative;
	transform: translate3d(var(--move-initial), 0, 0);
	animation: marquee 25s linear infinite;
	animation-play-state: running;
	opacity: 1;
	transition: opacity 0.1s;

	@media screen and (max-width: $break-small){
		animation: marquee 25s linear infinite;
	}
}

.marquee__inner {
	width: fit-content;
	display: flex;
	position: relative;
	transform: translate3d(var(--move-initial), 0, 0);
	animation: marquee 15s linear infinite;
	animation-play-state: paused;
	opacity: 0;
	transition: opacity 0.1s;
}

.menu__item:hover .menu__item-link ~ .marquee .marquee__inner {
	animation-play-state: running;
	opacity: 1;
	transition-duration: 0.4s;
}

.marquee span {
	opacity: 1;
}

.menu__item-link,
.marquee span {
	white-space: nowrap;
	padding: 0;
	// line-height: 1.15;
}

.marquee-lead span, .marquee span {
	padding: 0 0.15em;
}

@keyframes marquee {
	0% {
		transform: translate3d(var(--move-initial), 0, 0);
	}

	100% {
		transform: translate3d(var(--move-final), 0, 0);
	}
}