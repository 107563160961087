$theme-color: #ff00c8;

.home{
    $theme-color: hsl(46, 100%, 0%);
    $theme-color-2:   rgb(246, 176, 0);
    $theme-color-3:  hsl(42, 100%, 62%);

    background-color: $theme-color-3;
    color: $theme-color;

    a, h1{ 
        color: $theme-color;
    }

    .index-arrow svg path{
        fill: $theme-color;
    }

    #headline{
        // fill: $theme-color-2;
        // animation-name: colorchange;
        // animation-duration: 3s;
        // animation-iteration-count: infinite;
        // animation-direction: alternate;
        // animation-timing-function: linear; 
        fill: rgba(255, 255, 255, 1);
    }

    // @keyframes colorchange {
    //     0% {
    //         fill: #8EA604;
    //     }
    //     25% {
    //         fill: #F5BB00;
    //     }
    //     50% {
    //         fill: #EC9F05;
    //     }
    //     75% {
    //         fill: #D76A03;
    //     }
    //     100% {
    //         fill: #BF3100;
    //     }
    // }

    .marquee-lead span{
        color: $theme-color;
        // -webkit-text-stroke: 1px $theme-color;
    }

    .marquee__inner span{
        color: transparent;
        -webkit-text-stroke: 1px $theme-color;
    }

    @media screen and (max-width: $break-small){
		.marquee-lead span{
            color: $theme-color;
            -webkit-text-stroke: none;
        }
	}
}

.unagibomber{
    $theme-color: #982017;
    $theme-color-2: #3F6250;
    $theme-color-3: #E9EAE2;


    background-color: $theme-color-3;
    color: $theme-color;

    a{
        color: $theme-color;
    }

    .menu-footer span{
        color: $theme-color-2;
    }
    
    #headline, #footer-marquee textPath, #large-scroll-container{
        fill: $theme-color-2;
    }

    h2{
        -webkit-text-stroke: 1px $theme-color;
    }

    #large-block{
        -webkit-text-stroke: 1px $theme-color-2;

        &:hover{
            color: $theme-color-2;
            -webkit-text-stroke: $theme-color-2; 
        }
    }

    .rating{
        color: $theme-color;
    }

    #large-horizontal-outline textpath{
        fill: $theme-color-2; 
    }
}

.sweatyjesus{
    $theme-color: #3F6250;
    $theme-color-2: #BC911F;
    $theme-color-3: #f0edda;

    background-color: $theme-color-3;
    color: $theme-color;

    a{
        color: $theme-color;
    }

    .menu-footer span{
        color: $theme-color-2;
    }

    #headline, #footer-marquee textPath, #large-scroll-container{
        fill: $theme-color-2;
    }

    #large-block{
        -webkit-text-stroke: 1px $theme-color-2;

        &:hover{
            color: $theme-color-2;
            -webkit-text-stroke: $theme-color-2; 
        }
    }

    h2{
        -webkit-text-stroke: 1px $theme-color;
    }
}

.thetwins{
    $theme-color: #0026ff;
    $theme-color-2: hsl(232, 79%, 4%);
    $theme-color-3: #bdbbb1;

    background-color:  $theme-color-2;
    color: $theme-color-3;

    nav, nav a{
        color: $theme-color;
    }

    .menu-footer span{
        color: $theme-color;
    }

    #headline, #footer-marquee textPath, #large-scroll-container{
        fill: $theme-color;
    }

    #large-horizontal-outline textpath{
        stroke: $theme-color;
    }

    #large-block{
        -webkit-text-stroke: 1px $theme-color;

        &:hover{
            color: $theme-color;
            -webkit-text-stroke: $theme-color-2; 
        }
    }

    h2{
        -webkit-text-stroke: 1px $theme-color;
    }   
}


.stevepizazz{
    $theme-color: #cecece;
    $theme-color-2: #e4ae76;
    $theme-color-3: #af3732;

    background-color: $theme-color-3;
    color: $theme-color;

    a{
        color: $theme-color;
    }

    .menu-footer span{
        color: $theme-color-2;
    }

    #headline, #footer-marquee textPath, #large-scroll-container{
        fill: $theme-color-2;
    }

    #large-block{
        -webkit-text-stroke: 1px $theme-color-2;

        &:hover{
            color: $theme-color-2;
            -webkit-text-stroke: $theme-color-2; 
        }
    }

    h2{
        -webkit-text-stroke: 1px $theme-color-2;
    }
}




.broliath{
    $theme-color: #34454e;
    $theme-color-2: #ab2b20;
    $theme-color-3: #bfd8e6;

    background-color: $theme-color-3;
    color: $theme-color;

    a{
        color: $theme-color;
    }

    .menu-footer span{
        color: $theme-color-2;
    }

    #headline, #footer-marquee textPath, #large-scroll-container{
        fill: $theme-color-2;
    }

    #large-block{
        -webkit-text-stroke: 1px $theme-color-2;

        &:hover{
            color: $theme-color-2;
            -webkit-text-stroke: $theme-color-2; 
        }
    }

    h2{
        -webkit-text-stroke: 1px $theme-color;
    }
}

.noahandthegreatirradationevent{
    $theme-color: #e5ecbe;
    $theme-color-2: #ec1d70;
    $theme-color-3: #0a0e1b;

    background-color: $theme-color-3;
    color: $theme-color;

    a{
        color: $theme-color;
    }

    .menu-footer span{
        color: $theme-color-2;
    }

    #headline, #footer-marquee textPath, #large-scroll-container{
        fill: $theme-color-2;
    }

    #large-block{
        -webkit-text-stroke: 1px $theme-color-2;

        &:hover{
            color: $theme-color;
            -webkit-text-stroke: $theme-color-2; 
        }
    }

    h2{
        -webkit-text-stroke: 1px $theme-color;
    }
}

.mashedpotato{
    $theme-color: #02ffad; 
    $theme-color-2: hsl(223, 78%, 5%);
    $theme-color-3: #a4b4c8;

    background-color:  $theme-color-2;
    color: $theme-color-3;

    nav, nav a{
        color: $theme-color;
    }

    .menu-footer span{
        color: $theme-color;
    }

    #headline, #footer-marquee textPath, #large-scroll-container{
        fill: $theme-color;
    }

    #large-horizontal-outline textpath{
        stroke: $theme-color;
    }

    #large-block{
        -webkit-text-stroke: 1px $theme-color;

        &:hover{
            color: $theme-color;
            -webkit-text-stroke: $theme-color-2; 
        }
    }

    h2{
        -webkit-text-stroke: 1px $theme-color;
    }   
}

.spaghetti{
    $theme-color: #f10f2d;
    $theme-color-2: hsl(0, 100%, 3%);
    $theme-color-3: #bdbbb1;

    background-color:  $theme-color-2;
    color: $theme-color-3;

    nav, nav a{
        color: $theme-color;
    }

    .menu-footer span{
        color: $theme-color;
    }

    #headline, #footer-marquee textPath, #large-scroll-container{
        fill: $theme-color;
    }

    #large-horizontal-outline textpath{
        stroke: $theme-color;
    }

    #large-block{
        -webkit-text-stroke: 1px $theme-color;

        &:hover{
            color: $theme-color;
            -webkit-text-stroke: $theme-color-2; 
        }
    }

    h2{
        -webkit-text-stroke: 1px $theme-color;
    }   
}

.madlibs{
    $theme-color: #f1a90f;
    $theme-color-2: hsl(41, 100%, 3%);
    $theme-color-3: #bdbbb1;

    background-color:  $theme-color-2;
    color: $theme-color-3;

    input{
        color: $theme-color;
    }

    .menu-footer span{
        color: $theme-color;
    }

    #headline, #footer-marquee textPath, #large-scroll-container{
        fill: $theme-color;
    }

    #large-horizontal-outline textpath{
        stroke: $theme-color;
    }

    #large-block{
        -webkit-text-stroke: 1px $theme-color;

        &:hover{
            color: $theme-color;
            -webkit-text-stroke: $theme-color-2; 
        }
    }

    h2{
        -webkit-text-stroke: 1px $theme-color;
    }   
}

.rustyplumber{
    $theme-color: #308534;
    $theme-color-2: hsl(42, 19%, 89%);
    $theme-color-3: #1a241c;

    background-color:  $theme-color-2;
    color: $theme-color-3;

    a{
        color: $theme-color-3;
    }

    .menu-footer span{
        color: $theme-color;
    }

    #headline, #footer-marquee textPath, #large-scroll-container{
        fill: $theme-color;
    }

    #large-horizontal-outline textpath{
        stroke: $theme-color;
    }

    #large-block{
        -webkit-text-stroke: 1px $theme-color;

        &:hover{
            color: $theme-color;
            -webkit-text-stroke: $theme-color-2; 
        }
    }

    h2{
        -webkit-text-stroke: 1px $theme-color;
    }   
}


.unused-good-scheme{
    $theme-color: #0ff1c1;
    $theme-color-2: hsl(167, 100%, 2%);
    $theme-color-3: #bdbbb1;

    background-color:  $theme-color-2;
    color: $theme-color-3;

    nav, nav a{
        color: $theme-color;
    }

    .menu-footer span{
        color: $theme-color;
    }

    #headline, #footer-marquee textPath, #large-scroll-container{
        fill: $theme-color;
    }

    #large-horizontal-outline textpath{
        stroke: $theme-color;
    }

    #large-block{
        -webkit-text-stroke: 1px $theme-color;
    }

    h2{
        -webkit-text-stroke: 1px $theme-color;
    }   
}